// Import FirebaseAuth and getFirebase().
import React, {useEffect, useState} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import {navigate} from "gatsby";
import * as queryString from "query-string";

import Signedlayout, {accountTab} from "../components/Signedlayout";
import SelectProductCard from "../components/SelectProductCard";
import Product from "../components/Product";
import {getStringPriceFromCents, skeletonProducts} from "./my-products";
import {reduce} from "ramda";
import CreateProjectModal from "../components/CreateProjectModal";
import DeleteProjectModal from "../components/DeleteProjectModal";
import UpdateProjectModal from "../components/UpdateProjectModal";
import {getFirebase} from "../utils/signin";
import Layout from "../components/layout";

function LegalNotice({}) {

    return (
        <Layout>
            <div className="pt-24 pb-1">
                <div className="mx-auto">
                    <div className="w-full mx-auto">
                        <div>
                            <div className='py-14 text-center text-3xl'>
                                <h1>Mentions légales</h1>
                            </div>
                            <br/>
                            <div className='bg-white text-gray-600'>
                                <div className='container w-full mx-auto py-10'>
                                    <h3 className='font-bold text-xl'>EDITION DU SITE INTERNET</h3>
                                    Le site DeerHome (ci-après "le Site"), accessible à l’adresse https://deerhome.fr
                                    est
                                    édité par Raidouane EL MOUKHTARI (ci-après "l'Editeur"), dont le domicile est situé
                                    108
                                    Cours Saint-Louis, 33300 Bordeaux, France (N/A, dev.deerhome@gmail.com).
                                    <br/>
                                    <br/>
                                    <h3 className='font-bold text-xl'>REDACTION DU SITE INTERNET</h3>
                                    Directeur de la publication : Raidouane EL MOUKHTARI
                                    Contact : dev.deerhome@gmail.com
                                    <br/>
                                    <br/>
                                    <h3 className='font-bold text-xl'>REDACTION DES MENTIONS LEGALES</h3>
                                    Dénomination sociale : Captain Contrat
                                    Adresse siège social : 12 rue saint-fiacre, 75002 Paris
                                    Site internet : www.captaincontrat.com
                                    <br/>
                                    <br/>
                                    <h3 className='font-bold text-xl'>HEBERGEUR DU SITE INTERNET</h3>
                                    Le site DeerHome est hébergé par Netlify, N/A au capital de 100 000 euros, dont le
                                    siège
                                    social est situé 2343 3rd Street, 94107 San Francisco, États-Unis, joignable par le
                                    moyen suivant : +1 415-691-1573.
                                    <br/>
                                    <br/>
                                    <h3 className='font-bold text-xl'>RESPECT DE LA PROPRIÉTÉ INTELLECTUELLE</h3>
                                    Toutes les marques, photographies, textes, commentaires, illustrations, images
                                    animées
                                    ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui
                                    pourraient être utilisées pour faire fonctionner le Site et plus généralement tous
                                    les
                                    éléments reproduits ou utilisés sur le Site sont protégés par les lois en vigueur au
                                    titre de la propriété intellectuelle.
                                    Ils sont la propriété pleine et entière de l'Editeur ou de ses partenaires, sauf
                                    mentions particulières. Toute reproduction, représentation, utilisation ou
                                    adaptation,
                                    sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les
                                    applications informatiques, sans l'accord préalable et écrit de l'Editeur, sont
                                    strictement interdites. Le fait pour l'Editeur de ne pas engager de procédure dès la
                                    prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation
                                    desdites utilisations et renonciation aux poursuites.
                                    Seule l'utilisation pour un usage privé dans un cercle de famille est autorisée et
                                    toute
                                    autre utilisation est constitutive de contrefaçon et/ou d'atteinte aux droits
                                    voisins,
                                    sanctionnées par Code de la propriété intellectuelle.
                                    La reprise de tout ou partie de ce contenu nécessite l'autorisation préalable de
                                    l'Editeur ou du titulaire des droits sur ce contenu.
                                    <br/>
                                    <br/>
                                    <h3 className='font-bold text-xl'>LIENS HYPERTEXTES</h3>
                                    Le Site peut contenir des liens hypertexte donnant accès à d'autres sites web édités
                                    et
                                    gérés par des tiers et non par l'Editeur. L'Editeur ne pourra être tenu responsable
                                    directement ou indirectement dans le cas où lesdits sites tiers ne respecteraient
                                    pas
                                    les dispositions légales.
                                    La création de liens hypertexte vers le Site ne peut être faite qu'avec
                                    l'autorisation
                                    écrite et préalable de l'Editeur.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default LegalNotice;